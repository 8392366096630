<template>
  <div class="polarized">
    <div class="content">
      <img class="singleLight" :src="singleImg" />
    </div>
    <div class="radioGtoup" @click="radioClick">
      <label><input type="radio" name="radio1" value="1" checked />基础</label>
      <label><input type="radio" name="radio1" value="2" />优化</label>
      <label><input type="radio" name="radio1" value="3" />进阶</label>
      <label><input type="radio" name="radio1" value="4" />最好</label>
    </div>
    <div class="btnGroup">
      <button class="retBtn" @click="go" style="display: none">返回</button>
      <button class="tenetBtn" @click="videoClick">原理</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "polarized",
  data() {
    return {
      imgList: [],
      singleImg: ''
    }
  },
  created() {
    let imgList = ["http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/swimmingpool_0.jpg", "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/swimmingpool_1.jpg", "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/swimmingpool_2.jpg", "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/swimmingpool_3.jpg"];
    this.imgList = imgList;
    this.singleImg = this.imgList[0];
  },
  mounted() {},
  methods: {
    go() {
      this.$router.go(-1);
    },
    videoClick() {
      this.$router.push({path: '/PopularScienceHome/polaPrinciple'});
    },
    radioClick(e) {
      if(e.target.value){
        this.singleImg = this.imgList[e.target.value - 1];
      }
    },
  }
}
</script>

<style scoped lang="scss">
.polarized {
  width: 100vw;
  height: 76vh;
}
.content {
  width: 100%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.singleLight {
  width: 100%;
}
.radioGtoup {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioGtoup label {
  margin: 0 50px;
}
.radioGtoup input {
  margin-right: 5px;
  font-size: 40px;
}

.btnGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnGroup button {
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
}

</style>
